function handleFirstTab(e) {
  if (
    e.keyCode === 9 ||
    e.keyCode === 32 ||
    e.keyCode === 37 ||
    e.keyCode === 39
  ) {
    // the "I am a keyboard user" key
    document.body.classList.add('user-is-tabbing');
    window.removeEventListener('keydown', handleFirstTab);
  }
}
window.addEventListener('keydown', handleFirstTab);
