(function () {
  /////// Display breadcrumbs shadow relative to scroll bar position
  const scrollBreadcrumbs = document.querySelector('.breadcrumbs__list');

  if (!scrollBreadcrumbs) {
    return;
  }

  const breadcrumbs = document.querySelector('.breadcrumbs');
  const maxScrollLeft =
    scrollBreadcrumbs.scrollWidth - scrollBreadcrumbs.clientWidth;

  function breadcrumbsShadow() {
    if (scrollBreadcrumbs.scrollLeft === maxScrollLeft) {
      breadcrumbs.classList.add('breadcrumbs__align-right');
    } else if (scrollBreadcrumbs.scrollLeft === 0) {
      breadcrumbs.classList.add('breadcrumbs__align-left');
    } else {
      breadcrumbs.classList.remove('breadcrumbs__align-right');
      breadcrumbs.classList.remove('breadcrumbs__align-left');
    }
  }
  scrollBreadcrumbs.addEventListener('scroll', () => {
    breadcrumbsShadow();
  });
})(jQuery);
